.lineTitle {
  position: relative;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  padding-left: 10px;

  .titleBorder {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
    width: 4px;
    height: 20px;
    background-color: var(--primary-color);
  }
}