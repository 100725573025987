.supply {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  .top,.bottom {
    .today_value {
      font-size: 22px;
      font-weight: bold;
      color: #14D0E4;
    }
    .elec {
      color: rgba(255,255,255,0.65);
    }
    .line_through {
      position: relative;
      margin: 2px 0;
      border-bottom: 1px solid rgba(255,255,255,0.65);
      .line_head {
        position: absolute;
        left: 0;
        bottom: -1px;
        width: 40px;
        border-bottom: 1px solid #14D0E4;
      }
    }
  }
  .bottom {
    .month_value {
      font-size: 22px;
      color: #F1B326;
      font-weight: bold;
    }
    .line_through {
      .line_head {
        border-bottom: 1px solid #F1B326;
      }
    }
  }
}
.myProgress {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
  .info_des {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    .img_des {
      width: 80px;
      img {
        width: 100%;
        display: block;
      }
    }
    .top {
      flex: 1;
      .today_value {
        font-size: 18px;
        font-weight: bold;
        color: #14D0E4;
      }
      .elec {
        color: rgba(255,255,255,0.65);
      }
      .line_through {
        position: relative;
        margin: 2px 0;
        border-bottom: 1px solid rgba(255,255,255,0.65);
        .line_head {
          position: absolute;
          left: 0;
          bottom: -1px;
          width: 30px;
          border-bottom: 1px solid #14D0E4;
        }
      }
    }
  }
  
}
.progress_box {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}
.func_area {
  background: var(--component-background);
  padding: 10px;
  margin-bottom: 10px;
}
.pd_horizal {
  padding: 0 12px;
}