:root {
  --gradient-background: linear-gradient(270deg, #2cc4f9 0%, #0277bd 100%);
}

%line {
  content: "";
  position: absolute;
  opacity: 0.5;
  border-radius: 1px;
  width: 2px;
  top: 0;
  height: 100%;
  background: white;
}

%bracket {
  content: "";
  position: absolute;
  opacity: 0.5;
  width: 7px;
  top: 0;
  height: 100%;
  border: 1px solid white;
  z-index: 1;
}

%dot {
  content: "";
  position: absolute;
  opacity: 0.5;
  width: 4px;
  height: 4px;
  top: 50%;
  background: white;
  border-radius: 50%;
  z-index: 10;
  margin-top: -2px;
}

.App {
  height: 100%;
}

.central-menu-menu {
  .ant-menu-item-selected {
    .menuLink {
      background: linear-gradient(270deg, #2cc4f9 0%, #0277bd 100%);
      box-shadow: 0px 10px 15px 0px rgba(44, 196, 249, 0.26);
      border-radius: 2px;
      text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
      font-weight: 600;
      position: relative;
      &::before {
        @extend %line;
        left: 0;
      }
      &::after {
        @extend %line;
        right: 0;
      }
    }
    .ant-menu-title-content {
      position: relative;
      &::before {
        @extend %bracket;
        left: 20px;
        border-right: none;
      }
      &::after {
        @extend %bracket;
        right: 20px;
        border-left: none;
      }
    }
    .menuContent:first-child {
      position: relative;
      &::before {
        @extend %dot;
        left: 19px;
      }
    }
    .menuContent:last-child {
      position: relative;
      &::after {
        @extend %dot;
        left: -8px;
      }
    }
  }
}

.central-menu-sider {
  .ant-menu-item-selected {
    background: linear-gradient(270deg, #2cc4f9 0%, #0277bd 100%);
    box-shadow: 0px 10px 15px 0px rgba(44, 196, 249, 0.26);
    position: relative;
    font-weight: 500;
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 7px;
      background: var(--aside-background-color);
      height: 100%;
      border-left: 4px solid #2cc4f9;
    }
  }
}

.page {
  padding: 16px 12px 34px;
  min-height: calc(100vh - 50px);
  position: relative;
  &::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url("./assets/images/bg.svg");
    opacity: 0.6;
  }
  &__header {
    margin-bottom: 5px;
    padding-left: 10px;
    position: relative;
  }
  &__filter {
    background: var(--component-background);
    margin-bottom: 10px;
    padding: 10px 20px 0;
  }
  &__content {
    background: var(--component-background);
    padding: 15px 10px;
    min-height: calc(100vh - 180px);
    &__header {
      margin-bottom: 15px;
    }
    &__section-title {
      font-size: 16px;
      color: var(--text-color);
      padding-left: 11px;
      line-height: 24px;
      position: relative;
      margin-top: 35px;
      &::before {
        content: "";
        position: absolute;
        background: var(--primary-color);
        width: 4px;
        height: 16px;
        left: 0;
        top: 4px;
      }
    }
    &__footer {
      background-color: var(--component-background);
      margin-top: 20px;
      position: sticky;
      bottom: 0;
      z-index: 1000;
    }
  }
}

.action-buttons {
  .ant-btn-text {
    padding: 0;
    line-height: 30px;
    height: 30px;
    margin-right: 10px;
  }
  .ant-btn-link {
    padding: 0;
    line-height: 30px;
    height: 30px;
    margin-right: 10px;
    background: transparent !important;
    color: var(--primary-color) !important;
    &:disabled {
      color: rgba(255, 255, 255, 0.3) !important;
    }
  }
  .ant-btn > .anticon + span,
  .ant-btn > span + .anticon {
    margin-left: 2px;
  }
}

.ant-modal-header {
  background-color: var(--component-background);
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: var(--gradient-background);
  &:hover {
    background: var(--gradient-background);
  }
}

.ant-radio-group-solid .ant-radio-button-wrapper-disabled {
  background: #242425;
  &.ant-radio-button-wrapper-checked {
    background: linear-gradient(270deg, rgba(#2cc4f9, 0.25) 0%, rgba(#0277bd, 0.25) 100%) !important;
    color: rgba(255, 255, 255, 0.4);
  }
}

$invalid-color: #ff7875;

.device-invalid {
  background-color: $invalid-color;
  td {
    background-color: $invalid-color;
  }
  td.ant-table-cell-row-hover {
    background-color: $invalid-color !important;
  }
}
