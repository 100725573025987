.line_through {
  position: relative;
  margin: 2px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.65);

  .line_head {
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 40px;
    border-bottom: 1px solid #14D0E4;
  }
}

:global {

  .swiper-button-prev:after{
    font-size: 30px !important;
    margin-right: 30px;
  }

  .swiper-button-next:after {
    font-size: 30px !important;
    margin-left: 30px;
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
    background-color: #00ADFF;
    
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff!important;
  }
}
.swipe_item {
  text-align: center;
  width: 80px;
  margin: 0 20px;
  .swipe_item_text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}


.block {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.item {
  padding-left: 10px;
  height: 24px;
  position: relative;
  font-size: 12px;
  .pipe {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 6px;
  }
}