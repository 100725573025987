.border-wrapper {
  border-style: solid;
  border-width: 140px 188px 109px 197px;
  border-image-source: url("../../assets/images/border.svg");
  border-image-slice: 140 188 109 197;
  border-image-repeat: repeat;

  &-inner {
    margin: -110px -155px -80px -175px;
    position: relative;
  }
}
